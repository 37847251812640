import React from 'react';
import SEO from '../../../components/seo';
import SimpleHero from '../../../components/simple-hero';
import Navigation from '../../../components/navigation';
import BigParagraph from '../../../components/big-paragraph';

import { graphql } from 'gatsby';

import { Column, ColumnSection } from '../../../components/column-section';
import RelatedNewsSection from '../../../components/related-news-section';

import ReactMarkdown from 'react-markdown';
import { Languages } from '../../../tools/languages';
import { PrimaryButton } from '../../../components/buttons';
import { FormattedMessage } from 'react-intl';

import getCallToAction from '../../../tools/get-call-to-action';

const DutchSectorDetailPage = ({ location, data, language = Languages.NL }) => {
  const sector = data.strapiSectors;
  const pathName = location.pathname;

  return (
    <>
      <SEO
        pathName={pathName}
        title={sector.title}
        description={sector.description}
        lang={language}
      />
      <Navigation transparent />
      <main>
        <SimpleHero
          image={
            sector.image
              ? sector.image.childImageSharp.original.src
              : 'https://via.placeholder.com/640?text=Bavak'
          }
          alt={sector.title}
        >
          <h1>{sector.title}</h1>
          <p>{sector.description}</p>
          <div>
            <PrimaryButton to={`/contact?sector=${sector.title}`}>
              <FormattedMessage id="hero-contact-cta" />
            </PrimaryButton>
          </div>
        </SimpleHero>
        <ColumnSection>
          <RelatedNewsSection language={language} />
          <Column>
            <BigParagraph>{sector.introduction}</BigParagraph>
            <ReactMarkdown>{sector.content}</ReactMarkdown>
          </Column>
        </ColumnSection>
        {getCallToAction(sector.CTA, language)}
      </main>
    </>
  );
};

export const query = graphql`
  query DutchSectorDetail($slug: String!) {
    strapiSectors(slug: { eq: $slug }) {
      slug
      title: title_nl
      description: description_nl
      introduction: introduction_nl
      content: content_nl
      CTA {
        name
        title: title_nl
        description: description_nl
      }
      image {
        childImageSharp {
          original {
            src
          }
        }
      }
    }
  }
`;

export default DutchSectorDetailPage;
